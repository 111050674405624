import React, { createContext, useState, useEffect } from "react"

const defaultContext = {
  cart: [],
  addToCart: (_item) => {},
  removeFromCart: (_item) => {},
  updateQuantity: (_itemId, _bundleId, _qty) => {},
  clearCart: () => {},
  checkout: async () => {},
  cartTotals: () => 0,
}
export const CartContext = createContext(defaultContext)

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart")
    return savedCart ? JSON.parse(savedCart) : []
  })

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart))
  }, [cart])

  const addToCart = (item) => {
    const existingItem = cart.find(
      (cartItem) =>
        cartItem.id === item.id && cartItem.bundle_id === item.bundle_id
    )
    if (existingItem) {
      setCart(
        cart.map((cartItem) =>
          cartItem.id === item.id && cartItem.bundle_id === item.bundle_id
            ? { ...cartItem, qty: (cartItem.qty || 1) + (item.qty || 1) }
            : cartItem
        )
      )
      return
    }
    setCart([...cart, item])
  }

  const removeFromCart = (item) => {
    setCart(
      cart.filter(
        (cartItem) =>
          cartItem.id !== item.id && cartItem.bundle_id === item.bundle_id
      )
    )
  }

  const clearCart = () => {
    setCart([])
  }
  const cartTotals = () => {
    return cart.reduce((acc, item) => {
      return acc + Math.round(100 * (item.qty || 1) * item.price) / 100
    }, 0)
  }

  const updateQuantity = (itemId, bundleId, qty) => {
    setCart(
      cart.map((cartItem) =>
        cartItem.id === itemId && cartItem.bundle_id === bundleId
          ? { ...cartItem, qty }
          : cartItem
      )
    )
  }

  const checkout = async () => {
    return fetch("/api/checkout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cartItems: cart }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.url) {
          // Redirect to the Stripe checkout page
          window.location.href = data.url
        } else {
          console.error("Checkout error:", data.error)
        }
      })
      .catch((error) => {
        console.error("Error:", error)
      })
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        clearCart,
        checkout,
        cartTotals,
        updateQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
